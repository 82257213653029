.IDVerification {

  .pj-content-section-top{
    justify-content: flex-start;
  }

  .pj-login-subtitle {
    color: var(--picklejar-primary);
  }
}
